import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import consolew from '../images/console-icon-white.png'
import { GoMarkGithub } from 'react-icons/go'
import { FaTwitter } from 'react-icons/fa'

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: false
    }
  }

  /**
   * Hamburger menu toggle for mobile breakpoints
   */
  toggleNavMenu() {
    this.setState({
      menuActive: !this.state.menuActive
    })
  }

  render() {
    let menuClass = 'navbar-menu' + (this.state.menuActive ? ' is-active' : '')
//	let burgerClass = 'navbar-burger' + (this.state.menuActive ? ' is-active' : '')

    return (
      <nav className='navbar is-spaced is-primary' role="navigation" aria-label="main navigation" >
        <div className='container'>
          <div className='navbar-brand'>
            <Link to='/' className='navbar-item'>
              <img src={consolew} alt={this.props.siteTitle} style={{paddingRight: `.245rem`, maxHeight: `2rem`}} />
              <h1 className='title has-text-white'>{this.props.siteTitle}</h1>
            </Link>
            <a href={this.props.github} className='navbar-item is-hidden-desktop'>
                <GoMarkGithub className='navbar-icon' color='white'/>
              </a>
              <a href={this.props.twitter} className='navbar-item is-hidden-desktop'>
                <FaTwitter className='navbar-icon' color='white'/>
              </a>
            {/* eslint-disable-next-line */}
		{/*
            <a className={burgerClass} aria-label='menu' aria-expanded='false' data-target='navbarLinks'
                onClick={this.toggleNavMenu.bind(this)}>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </a>
		*/}
          </div>
          <div id='navbarLinks' className={menuClass}>
		{/*
            <div className='navbar-start'>
              <Link to='/about' className='navbar-item'>
                About
              </Link>
            </div>
		*/}
            <div className='navbar-end'>
              <a href={this.props.github} className='navbar-item is-hidden-touch'>
                <GoMarkGithub className='navbar-icon' color='white'/>
              </a>
              <a href={this.props.twitter} className='navbar-item is-hidden-touch'>
                <FaTwitter className='navbar-icon' color='white'/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }

}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  twitter: PropTypes.string,
  github: PropTypes.string
}

Navigation.defaultProps = {
  siteTitle: ``,
  twitter: `https://www.twitter.com`,
  github: `https://www.github.com`
}
