/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import "./layout.scss"
import Navigation from "./nav";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        site {
          siteMetadata {
            title
            twitter
            github
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          meta={[
            {
              name: `theme-color`,
              content: `#00d1b2`,
            },
          ]}
        />
        <Navigation
          siteTitle={data.site.siteMetadata.title}
          twitter={data.site.siteMetadata.twitter}
          github={data.site.siteMetadata.github}
        />
        <main className='has-background-light'>{children}</main>
        <section style={{ 'backgroundColor':'#00d1b2' }}>
          <footer className='footer'>
            <div className='container'>
              <div className='has-text-centered has-text-white'>
                <strong className='has-text-white'>tristanfarmer.dev</strong> by Tristan Farmer
              </div>
            </div>
          </footer>
        </section>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
